import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import {
  UpTitle, Title2, Title3 as Title3V3, mobileThresholdPixels,
} from './styledComponents';
import routes from '../../../Routes';
import { toFormattedLinkedText } from '../../../services/formatting';
import retoucheImg from '../../../assets/services/cloth.jpg';
import creationImg from '../../../assets/services/creation.jpg';
import broderieImg from '../../../assets/services/embroidery.jpg';
import transformationImg from '../../../assets/services/transfo.jpg';
import retoucheDecoImg from '../../../assets/services/deco.jpg';
import creationDecoImg from '../../../assets/services/decoCreation.jpg';
import broderieDecoImg from '../../../assets/services/decoEmbroidery.jpg';
import transformationDecoImg from '../../../assets/services/decoTransfo.jpg';
import topCurve from '../../../assets/services/topCurve.png';
import bottomCurve from '../../../assets/services/bottomCurve.png';
import { pushToLayer } from '../../../services/googleTagManager';

const dressingServices = [
  {
    img: retoucheImg,
    imgAlt: 'Retouche de vêtements par une couturière',
    title: 'Retouche & réparation',
    slug: 'Clothes',
    text: 'D’un simple bouton à <link>la retouche<link> de votre robe de mariée, les expertises de nos Tillistes permettent de répondre à toutes vos demandes.',
    textLinks: ['/retouche-vetement/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'clothes', selectedTypeOfWork: 'alteration' },
    },
  },
  {
    img: creationImg,
    imgAlt: 'Un sac à main créé à partir de chutes de tissus',
    title: 'Création d’accessoires',
    slug: 'Creation',
    text: 'Co-créez avec l’un de nos Tillistes <link>l’accessoire<link> de vos rêves, à partir de chutes de tissus ou d’anciens vêtements.',
    textLinks: ['/la-rubrique/creation/accessoires/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'clothes', selectedTypeOfWork: 'creation' },
    },
  },
  {
    img: broderieImg,
    imgAlt: 'Une couturière brode du textile',
    title: 'Broderie',
    slug: 'Embroidery',
    text: 'Personnalisez vos vêtements ou accessoires avec <link>de jolies broderies<link> réalisées à la main par nos couturiers.',
    textLinks: ['/la-rubrique/broderie/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'clothes', selectedTypeOfWork: 'embroidery' },
    },
  },
  {
    img: transformationImg,
    imgAlt: 'Une couturière fait une transformation de vêtement',
    title: 'Upcycling',
    slug: 'Transformation',
    text: 'Grâce à leur expérience de stylisme, nos couturiers vous conseillent sur <link>les modifications à apporter<link> à vos vêtements pour les rendre tendance.',
    textLinks: ['/la-rubrique/transformation/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'clothes', selectedTypeOfWork: 'transformation' },
    },
  },
];

const decoServices = [
  {
    img: retoucheDecoImg,
    imgAlt: 'Retouche de vêtements par une couturière',
    title: 'Retouche & réparation',
    slug: 'Deco',
    text: 'Faites facilement <link>retoucher et réparer votre décoration<link> directement à domicile en prenant rendez-vous avec nos artisans couturier.',
    textLinks: ['/la-rubrique/retouche/linge-de-maison/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'deco', selectedTypeOfWork: 'alteration' },
    },
  },
  {
    img: creationDecoImg,
    imgAlt: 'Un sac à main créé à partir de chutes de tissus',
    title: 'Création de décoration',
    slug: 'DecoCreation',
    text: 'Donnez une seconde vie à votre mobilier et embellissez vos intérieurs de façon responsable avec votre Tilliste.',
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'deco', selectedTypeOfWork: 'creation' },
    },
  },
  {
    img: broderieDecoImg,
    imgAlt: 'Une couturière brode du textile',
    title: 'Broderie',
    slug: 'DecoEmbroidery',
    text: 'Personnalisez votre linge de maison et vos tissus d’ameublement avec <link>des broderies sur-mesure<link>.',
    textLinks: ['/la-rubrique/broderie/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'deco', selectedTypeOfWork: 'embroidery' },
    },
  },
  {
    img: transformationDecoImg,
    imgAlt: 'Une couturière fait une transformation de vêtement',
    title: 'Upcycling',
    slug: 'DecoTransformation',
    text: 'Nos Tillistes vous accompagnent dans vos projets de <link>transformation<link> pour prolonger la vie de vos éléments de déco et retravailler vos chutes de tissu.',
    textLinks: ['/la-rubrique/transformation/'],
    link: {
      url: routes.Step1.url,
      state: { selectedCategory: 'deco', selectedTypeOfWork: 'transformation' },
    },
  },
];

const margins = '135px';
const mobileMargins = '15px';

const Container = styled.div`
  position: relative;
  padding: 74px ${margins} 40px ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 50px ${mobileMargins} 5px;
  }
`;

const ServicesContainer = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScrollingRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 35px -${margins} 110px;
  width: calc(100% + ${margins} * 2);
  height: 335px;
  padding-bottom: 30px;
  overflow-x: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ isHovered, theme }) => isHovered ? theme.colors.lightGrey3 : 'transparent'};
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 0 calc(150px + ${margins}) 0;
    background-color: ${({ isHovered, theme }) => isHovered ? theme.colors.lightGrey : 'transparent'};
  }

  @media (max-width: 1660px) {
    justify-content: flex-start;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px -${mobileMargins} 45px ;
    width: calc(100% + ${mobileMargins} * 2);
    padding-bottom: 0px;
    height: 282px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Row = styled.div`
  position: absolute;
  display: flex;
`;

const Service = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 325px;
  min-width: 325px;
  margin-left: ${({ isFirst }) => isFirst ? margins : '0px'};
  margin-right: ${({ isLast }) => isLast ? margins : '30px'};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 275px;
    min-width: 275px;
    margin-left: ${({ isFirst }) => isFirst ? mobileMargins : '0px'};
    margin-right: ${({ isLast }) => isLast ? mobileMargins : '10px'};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 210px;
  object-fit: cover;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 145px;
  }
`;

const Title = styled.h2`
  align-self: flex-start;
  font-family: Libre Baskerville;
  color: ${({ theme }) => theme.colors.navy};
  line-height: 30px;
  font-size: ${({ theme }) => theme.fontSizes.m};
  margin: 20px 0 10px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Title3 = styled(Title3V3)`
  width: 100%;
  max-width: 1390px;
  z-index: 1;
`;

const TextSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin: 0;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.navy};
`;

const Line = styled.div`
  width: 100%;
  max-width: 1390px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGrey3};
  margin: 5px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 10px 0;
  }
`;

const TopCurve = styled.img`
  position: absolute;
  top: -375px;
  right: -125px;
  z-index: 0;
  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

const BottomCurve = styled.img`
  position: absolute;
  bottom: -300px;
  left: -10px;
  z-index: 0;
  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

const ServicesRow = ({ title, services }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Title3 style={{ zIndex: 1 }}>{title}</Title3>
      <Line />
      <ScrollingRow isHovered={isHovered} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Row>
          {services.map((service, index) => (
            <Service key={service.title} isFirst={index === 0} isLast={index === services.length - 1}>
              <Link
                to={service.link.url}
                state={service.link.state}
                onClick={() => pushToLayer({
                  event: 'Order Funnel Started',
                  from: window.location.pathname,
                  from_interaction: `OurServices_${service.slug}`,
                  funnel_type: 'standard',
                })}
                style={{ textDecoration: 'none' }}
              >
                <Image
                  src={service.img}
                  alt={service.imgAlt}
                  loading="lazy"
                />
              </Link>
              <TextSubContainer>
                <Link to={service.link.url} state={service.link.state} style={{ textDecoration: 'none' }}>
                  <Title>{service.title}</Title>
                </Link>
                <Text>
                  {service.text.includes('<link>')
                    ? toFormattedLinkedText(service.text, service.textLinks, { textDecoration: 'none' })
                    : service.text}
                </Text>
              </TextSubContainer>
            </Service>
          ))}
        </Row>
      </ScrollingRow>
    </>
  );
};

ServicesRow.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
};

ServicesRow.defaultProps = {
  services: [],
  title: '',
};

const Services = () => (
  <Container>
    <TopCurve src={topCurve} alt="nos services de retouche à domicile" />
    <BottomCurve src={bottomCurve} alt="couturière à Paris, Lyon, Bordeaux et Marseille" />
    <UpTitle>Nos services</UpTitle>
    <Title2 marginBottom={100}>Que peut-on faire pour vous ?</Title2>
    <ServicesContainer>
      <ServicesRow title="Votre dressing" services={dressingServices} />
      <ServicesRow title="Votre décoration" services={decoServices} />
    </ServicesContainer>
  </Container>
);

export default Services;
