import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  UpTitle, Title2, Button, mobileThresholdPixels,
} from './styledComponents';
import HowItWorksProgressBar from './HowItWorksProgressBar';
import routesMap from '../../../Routes';
import { pushToLayer } from '../../../services/googleTagManager';

const Container = styled.div`
  padding: 74px 0px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 50px 0px 15px;
  }
`;

const HowItWorks = () => (
  <Container>
    <UpTitle>Votre rendez-vous</UpTitle>
    <Title2 marginBottom={75}>Comment ça marche ?</Title2>
    <HowItWorksProgressBar />
    <Link
      to={routesMap.Step1.url}
      onClick={() => pushToLayer({
        event: 'Order Funnel Started',
        from: window.location.pathname,
        from_interaction: 'HowItWorks',
        funnel_type: 'standard',
      })}
    >
      <Button navy>Prendre rendez-vous</Button>
    </Link>
  </Container>
);

export default HowItWorks;
